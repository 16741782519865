<template>
  <section id="myInformation" class="flex flex-col gap-y-3 lg:gap-y-3 h-full">
    <div class="flex flex-col gap-y-6 lg:gap-y-0">
      <!-- Active Code : #FD5B68 | #000 -->
      <div class="flex lg:hidden flex-row bg-white py-3">
        <svg
          class="cursor-pointer"
          @click="openMobileNav"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#111"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 18l-6-6 6-6"
          />
        </svg>
        <div class="flex-1 flex justify-center font-EffraM text-center text-lg">
          Email and password
        </div>
      </div>
      <h1 class="font-EffraM text-xl px-8">
        Identifiants actuels
      </h1>
    </div>

    <ValidationObserver
      ref="observer"
      class="flex flex-col gap-y-8 lg:gap-y-16 px-8"
    >
      <section>
        <div class="grid gap-x-8 gap-y-6 grid-cols-1 w-full lg:w-1/2">
          <ValidationProvider
            :bails="false"
            name="Email"
            rules="required"
            id="email_address"
            class="w-full"
          >
            <template slot-scope="{ errors }">
              <dok-input
                label="Email"
                d-placeholder="Email"
                size="lg"
                d-type="email"
                :d-model.sync="user.email"
                v-model="user.email"
                :custom-class="
                  errors[0] ? 'border-rosDok-ultra w-full' : 'w-full'
                "
              >
                <template v-slot:leftIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                    />
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M22 6l-10 7L2 6"
                    />
                  </svg>
                </template>
              </dok-input>
            </template>
          </ValidationProvider>
          <ValidationProvider
            :bails="false"
            name="Mot de passe actuel"
            rules="required"
            id="currect_password"
            class="w-full"
          >
            <template slot-scope="{ errors }">
              <dok-input
                label="Mot de passe"
                d-placeholder="Mot de passe"
                size="lg"
                d-type="password"
                :d-model.sync="actualPassword"
                v-model="actualPassword"
                :custom-class="
                  errors[0] ? 'border-rosDok-ultra w-full' : 'w-full'
                "
              >
                <template v-slot:leftIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 11H5c-1.105 0-2 .895-2 2v7c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2v-7c0-1.105-.895-2-2-2zM7 11V7c0-1.326.527-2.598 1.464-3.536C9.402 2.527 10.674 2 12 2s2.598.527 3.536 1.464C16.473 4.402 17 5.674 17 7v4"
                    />
                  </svg>
                </template>
              </dok-input>
            </template>
          </ValidationProvider>
        </div>
      </section>

      <section class="flex flex-col gap-y-3">
        <h1 class="font-EffraM text-xl">Changer le mot de passe</h1>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 items-end gap-y-3">
          <div class="order-1 grid gap-x-8 gap-y-6 grid-cols-1">
            <ValidationProvider
              :bails="false"
              name="password"
              rules="required|verify_password|min:8"
              id="password"
              class="w-full"
            >
              <template slot-scope="{ errors }">
                <dok-input
                  label="Nouveau mot de passe"
                  d-placeholder="Nouveau mot de passe"
                  size="lg"
                  d-type="password"
                  :d-model.sync="newPassword"
                  v-model="newPassword"
                  :custom-class="
                    errors[0] ? 'border-rosDok-ultra w-full' : 'w-full'
                  "
                >
                  <template v-slot:leftIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 11H5c-1.105 0-2 .895-2 2v7c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2v-7c0-1.105-.895-2-2-2zM7 11V7c0-1.326.527-2.598 1.464-3.536C9.402 2.527 10.674 2 12 2s2.598.527 3.536 1.464C16.473 4.402 17 5.674 17 7v4"
                      />
                    </svg>
                  </template>
                </dok-input>
              </template>
            </ValidationProvider>
            <ValidationProvider
              :bails="false"
              name="Confirmer le mot de passe"
              rules="required|confirmed:password"
              id="confirm_password"
              class="w-full"
            >
              <template slot-scope="{ errors }">
                <dok-input
                  label="Confirmer le nouveau mot de passe"
                  d-placeholder="Confirmer le nouveau mot de passe"
                  size="lg"
                  d-type="password"
                  :d-model.sync="confirmNewPassword"
                  v-model="confirmNewPassword"
                  :custom-class="
                    errors[0] ? 'border-rosDok-ultra w-full' : 'w-full'
                  "
                >
                  <template v-slot:leftIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 11H5c-1.105 0-2 .895-2 2v7c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2v-7c0-1.105-.895-2-2-2zM7 11V7c0-1.326.527-2.598 1.464-3.536C9.402 2.527 10.674 2 12 2s2.598.527 3.536 1.464C16.473 4.402 17 5.674 17 7v4"
                      />
                    </svg>
                  </template>
                </dok-input>
              </template>
            </ValidationProvider>
          </div>
          <div class="order-2">
            <div class="bg-bginfo p-5 flex flex-col gap-y-2 rounded-cu">
              <div
                class="flex flex-row gap-x-2 items-center font-EffraM text-base"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      stroke="#111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 14.667c3.682 0 6.667-2.985 6.667-6.667S11.682 1.333 8 1.333 1.333 4.319 1.333 8 4.318 14.667 8 14.667zM8 10.667V8M8 5.333h.007"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <path fill="#fff" d="M0 0H16V16H0z" />
                    </clipPath>
                  </defs>
                </svg>
                Le mot de passe doit contenir:
              </div>
              <ul class="pl-3 font-EffraR text-black text-base">
                <li>Au moins 8 caractères</li>
                <li>Au moins une majuscule</li>
                <li>Au moins un chiffre</li>
              </ul>
            </div>
            <div
              v-if="newPassword && newPassword.length"
              class="h-1 rounded-full my-2"
              :style="
                `width: ${percentPassword()}%; background: ${percentBackground(
                  percentPassword()
                )}`
              "
            ></div>
          </div>
        </div>
      </section>
    </ValidationObserver>

    <div id="actions" class="flex flex-row justify-between px-8">
      <dok-button size="md" bg="gray" custom-class="uppercase px-8"
        >Ignorer</dok-button
      >
      <dok-button
        size="md"
        bg="rose"
        custom-class="uppercase px-8"
        @click.native="onSave"
      >
        Sauvegarder
      </dok-button>
    </div>
  </section>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      page: 1,
      user: this.$auth.getUserInfo(),
      // FILDS COMPONENTS
      actualPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      validation: {
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        has_length: false
      },
      showpassword: {
        first: false,
        second: false,
        last: false
      },
      currentPasswordError: false
    };
  },
  watch: {
    validation: {
      handler(newValue, oldValue) {
        this.percentPassword();
      },
      deep: true
    },
    newPassword(newVal, oldVal) {
      this.validation.has_number = /\d/.test(this.newPassword);
      this.validation.has_lowercase = /[a-z]/.test(this.newPassword);
      this.validation.has_uppercase = /[A-Z]/.test(this.newPassword);
      this.validation.has_length = this.newPassword.length > 7;
      this.validation.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(
        this.newPassword
      );
    }
  },
  methods: {
    async changeEmail() {
      this.$vs.loading();
      await this.$store.dispatch("user/UPDATE_PROFILE", {
        data: { email: this.user.email },
        onData: ({ ok, data, message }) => {
          if (ok) {
            console.log(data);
            this.$auth.setUserInfo(data);
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Modification effectuée avec succès!",
              timerAlert: 8000
            });

            this.$nextTick(() => {
              this.$vs.loading.close();
              return;
            });
            return;
          }

          EventBus.$emit("flashDok", {
            type: "errorr",
            message: message,
            timerAlert: 8000
          });

          this.$nextTick(() => {
            this.$vs.loading.close();
            return;
          });
        }
      });
    },
    async onSave() {
      if (
        this.user.email !== this.$auth.getUserInfo().email &&
        this.user.email !== ""
      ) {
        this.changeEmail();
        return;
      }

      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      if (this.percentPassword() !== 100) {
        return;
      }

      this.$vs.loading();
      this.$store.dispatch("auth/UPDATE_PASSWORD", {
        data: {
          actualPass: this.actualPassword,
          newPass: this.newPassword,
          confPass: this.confirmNewPassword
        },
        onData: data => {
          EventBus.$emit("flashDok", {
            type: data.ok ? "success" : "errorr",
            message: data.message,
            timerAlert: 8000
          });

          if (!data.ok) {
            if (data.message === "Le mot de passe actuel n'est pas correcte") {
              this.$vs.loading.close();
              return (this.currentPasswordError = true);
            }
          }

          if (data.ok) {
            this.actualPassword = ""; // Not Null empty String
            this.newPassword = ""; // Not Null empty String
            this.confirmNewPassword = ""; // Not Null empty String
            this.$refs.observer.reset();
          }

          this.$vs.loading.close();
        }
      });
    },
    openMobileNav() {
      EventBus.$emit("Change_Page_Number_My_Account_Patient", -1);
    },
    percentPassword() {
      let precent = 0;
      if (this.validation.has_number) precent = precent + 20;
      if (this.validation.has_lowercase) precent = precent + 20;
      if (this.validation.has_uppercase) precent = precent + 20;
      if (this.validation.has_special) precent = precent + 20;
      if (this.validation.has_length) precent = precent + 20;

      return precent;
    },
    percentBackground(val) {
      if (val === 0) return "#DDD";
      if (val === 20) return "#EF4444";
      if (val === 40) return "#DB2777";
      if (val === 60) return "#D97706";
      if (val === 80) return "#fbbf24";
      if (val === 100) return "#10B981";
    }
  }
};
</script>
